import React from "react";
import { Route } from "react-router-dom";
import loadLazy from "~/utils/lazy";

import AuthenticatedRoute from "~/components/routing/AuthenticatedRoute";

const HomePage = loadLazy(() => import("./"));

const AuthRoutes = AuthenticatedRoute(
    <React.Fragment>
        <Route path="/" element={<HomePage />} />
        <Route path="/interests/:id" element={<HomePage />} />
    </React.Fragment>
);

export default AuthRoutes;
