import React from "react";
import { Provider } from "~/state";

// This is where you specify all global-level providers (e.g. Material-UI, global contexts, redux provider, etc.)
const Providers = ({ children }) => (
    <Provider>
        {children}
    </Provider>
);

export default Providers;
