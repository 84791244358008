import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import Providers from "./Providers";

import AuthRoutes from "./auth/routes";
import LandingPageRoutes from "./home/routes";

export default (
    <Providers>
        <BrowserRouter>
            <Routes>
                {AuthRoutes}
                {LandingPageRoutes}
            </Routes>
        </BrowserRouter>
    </Providers>
);
