import React from "react";
import { Route } from "react-router-dom";
import loadLazy from "~/utils/lazy";

const LoginPage = loadLazy(() => import("./login"));

const AuthRoutes = (
    <Route path="/login" element={<LoginPage />} />
);

export default AuthRoutes;
