import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider as ReduxProvider } from "react-redux";
import thunk from "redux-thunk";

import reducer from "./reducers";

/** @type {compose} */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);

export default store;

export const Provider = props => (
    <ReduxProvider {...props} store={store} />
);
