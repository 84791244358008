import LocalStorage from "~/services/LocalStorage";

import UserActions from "~/state/actions/user";

export default function user(state = initialUserState, action) {
    switch (action.type) {
        case UserActions.LOGIN: return {
            ...state,
            userName: action.formData.username
        };

        default:
            return state;
    }
}

const initialUserState = {
    userName: LocalStorage.getItem("noinc.username", "")
};
